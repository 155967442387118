<template>
	<div v-if="layoutStore.isAuthenticated" class="personal-overview d-flex flex-column">
		<p class="logged-in-user-text mb-0 px-1">
			<i18n-t v-if="layoutStore.isImpersonated" keypath="menu.loggedInAsCustomer">
				<template #user>
					<b v-if="vm.impersonatedUser?.firstName" class="mb-0 px-1 text-info">
						<a
							class="d-block"
							:href="vm?.impersonatedUser?.crmUrl"
							target="_blank"
							:title="$t('common.goToCrm')"
						>
							{{ vm.impersonatedUser?.firstName }} {{ vm.impersonatedUser?.lastName }}
						</a>
					</b>
					<b v-else class="mb-0 px-1 text-info">
						<a
							class="d-block text-break"
							:href="vm?.impersonatedUser?.crmUrl"
							target="_blank"
							:title="$t('common.goToCrm')"
						>
							{{ vm.impersonatedUser?.displayName }}
						</a>
					</b>
					<div class="wallet-text pr-1">
						<small class="d-block">{{ vm.currentUser?.company?.companyName }}</small>
						<small class="d-block text-fade">{{
							vm.currentUser?.company?.cadacOrganizationCode
						}}</small>
					</div>
				</template>
			</i18n-t>
			<i18n-t v-else keypath="menu.loggedInAs">
				<template #user>
					<div v-if="vm.currentUser?.firstName" class="mb-0 d-block text-break">
						<div class="wallet-text pr-1">
							<b>{{ vm.currentUser?.firstName }} {{ vm.currentUser?.lastName }}</b>
							<small v-if="vm.currentUser?.isEmailVerified" class="d-block">{{
								vm.currentUser?.company?.companyName
							}}</small>
							<small v-if="vm.currentUser?.isEmailVerified" class="d-block text-fade">{{
								vm.currentUser?.company?.cadacOrganizationCode
							}}</small>
						</div>
					</div>
					<b v-else class="mb-0 d-block text-break"> {{ vm.currentUser?.displayName }} </b>
				</template>
			</i18n-t>
		</p>

		<Divider />

		<Button
			v-if="vm.myCadacPageUrl && !shouldVerifyEmail"
			class="p-button-primary p-button-raised ml-1 mb-2"
			icon="pi pi-arrow-right ml-2"
			icon-pos="right"
			:label="$t('menu.button.myCadac')"
			@click="onGoToMyCadacClick()"
		/>
		<Button
			v-else-if="vm.myCadacPageUrl"
			class="p-button-success p-button-raised ml-1 mb-2"
			icon="pi pi-arrow-right ml-2"
			icon-pos="right"
			:label="$t('menu.button.verifyEmail')"
			@click="onGoToMyCadacClick()"
		/>
		<Button
			v-if="vm.organizationOptions?.length > 1"
			class="p-button-secondary p-button-rounded p-button-text ml-0"
			@click="onChangeCompanyClick()"
		>
			<font-awesome-icon class="mr-1" :icon="['far', 'arrow-right-arrow-left']" />
			<span>{{ $t("menu.button.changeOrganisation") }}</span>
		</Button>

		<div v-if="!layoutStore.isImpersonated" class="d-flex flex-column">
			<Button
				class="p-button-secondary p-button-rounded p-button-text"
				:label="$t('menu.button.logout')"
				@click="onLogoutClick()"
			>
				<font-awesome-icon class="mr-1" :icon="['far', 'arrow-right-from-bracket']" />
				<span>{{ $t("menu.button.logout") }}</span>
			</Button>
		</div>
		<div v-else class="d-flex flex-column">
			<Button
				class="p-button-text p-button-rounded font-weight-normal ml-0"
				:title="$t('menu.button.refreshUserData_title')"
				@click="refreshUserInfo()"
			>
				<font-awesome-icon class="mr-1" :icon="['far', 'rotate-right']" size="lg" />
				<span>{{ $t("menu.button.refreshUserData_title") }}</span>
			</Button>
			<Button
				class="p-button-text p-button-rounded p-button-secondary ml-0"
				:title="$t('menu.button.cancelImpersonation_title')"
				@click="cancelImpersonation()"
			>
				<font-awesome-icon class="mr-1" :icon="['far', 'arrow-right-from-bracket']" size="lg" />
				<span>{{ $t("menu.button.cancelImpersonation_title") }}</span>
			</Button>
		</div>
	</div>
	<div v-else class="d-flex flex-column">
		<p class="p-1">{{ $t("menu.youAreNotLoggedIn") }}</p>
		<Button
			class="p-button-success p-button-raised ml-1 mb-1"
			:label="$t('common.login')"
			@click="onLoginClick()"
		/>
		<Button class="p-button-text ml-1 mb-1" :label="$t('common.register')" @click="onCreateAccountClick()" />
	</div>
</template>

<script lang="ts">
import Divider from "primevue/divider";
import OverlayPanel from "primevue/overlaypanel";
import BaseComponent from "@/components/base/baseComponent.vue";
import { Component } from "vue-facing-decorator";
import { IMainLayout } from "@/types/viewModels/common/mainLayoutViewModel";

@Component({
	components: { OverlayPanel, Divider },
})
export default class MainUserMenu extends BaseComponent {
	get vm(): IMainLayout {
		return this.layoutStore.vm as IMainLayout;
	}

	get shouldVerifyEmail(): boolean {
		return !this.layoutStore.isImpersonated && !this.layoutStore.vm.currentUser.isEmailVerified;
	}

	async onLoginClick(): Promise<void> {
		await localStorage.setItem("showedCompanyModal", "false");
		this.openUrl(this.loginPageUrl);
	}

	onCreateAccountClick(): void {
		this.openUrl(this.registerPageUrl);
	}

	async onLogoutClick(): Promise<void> {
		await localStorage.setItem("showedCompanyModal", "false");
		window.location.href = "/MyCadacStore/Logout";
	}

	onGoToMyCadacClick(): void {
		this.openUrl(this.vm.myCadacPageUrl);
	}

	onGoToAccountSettingsClick(): void {
		this.openUrl(this.vm.accountSettingsPageUrl);
	}

	onChangeCompanyClick(): void {
		this.layoutStore.showModal("ChangeCompany");
	}

	cancelImpersonation(): void {
		this.loadingStore.increaseLoadingCount();
		window.location.href = `/MyCadacStore/RevertImpersonation`;
	}

	refreshUserInfo(): void {
		this.loadingStore.increaseLoadingCount();
		window.location.href = `/MyCadacStore/RefreshUser`;
	}
}
</script>

<style scoped lang="scss">
.personal-overview {
	.badge {
		background: var(--primary-color);
		color: var(--primary-color-text);
		font-size: 0.9rem;
		border-radius: 3rem;
	}

	.p-button {
		width: fit-content;
	}

	.p-button-secondary {
		font-size: 1.05rem;
		font-weight: 400;
	}

	.logged-in-user-text {
		font-size: 1.3rem;
	}

	.wallet-text {
		font-size: 1.3rem;
	}
}
</style>
