<template>
	<h1 v-if="vm.pageTitle">{{ vm.pageTitle }}</h1>

	<TabView v-model:activeIndex="activeIndex" @tab-change="onTabChange">
		<TabPanel :key="enumTabs.PERSONAL" v-if="shouldShowTab(enumTabs.PERSONAL)" :header="$t('myCadac.accountSettings.tabView.headerPersonal')">
			<PersonalForm v-model="personalFormModel" ref="personalForm" />
			<Button class="p-button-success p-button-raised p-button-lg"
					:disabled="!canSubmit"
					:label="$t('common.save')"
					:loading="isLoading"
					@click="onSubmitClick" />
		</TabPanel>
		<TabPanel :key="enumTabs.PASSWORD" v-if="shouldShowTab(enumTabs.PASSWORD)" :header="$t('myCadac.accountSettings.tabView.headerLogin')">
			<div class="form-row">
				<div class="form-group col-md-6 col-xxl-4">
					<label>{{ $t("myCadac.accountSettings.lblEmail") }}</label>
					<div class="d-flex align-items-center">
						<InputText v-model="vm.login.email" class="mr-1 p-disabled" readonly="true" type="email" />
						<PopoverIcon class="d-inline-flex">
							<i class="popover-icon pi pi-question" />
							<template #content>
								<div v-html="$t('myCadac.accountSettings.changeEmailHelpText')" />
							</template>
						</PopoverIcon>
					</div>
				</div>
			</div>
			<NewPasswordForm v-model="passwordFormModel" ref="passwordForm" />
			<Button class="p-button-success p-button-raised p-button-lg"
					:disabled="!canSubmit"
					:label="$t('common.save')"
					:loading="isLoading"
					@click="onSubmitClick" />
		</TabPanel>
		<TabPanel :key="enumTabs.COMPANY" v-if="shouldShowTab(enumTabs.COMPANY)" :header="$t('myCadac.accountSettings.tabView.headerOrganisation')">
			<CompanyForm v-model="companyFormModel" ref="companyForm" :countries="vm.countries" use-vat />
			<Button class="p-button-success p-button-raised p-button-lg"
					:disabled="!canSubmit"
					:label="$t('common.save')"
					:loading="isLoading"
					@click="onSubmitClick" />
			<RelatedContactTable :items="vm.relatedContacts" />
		</TabPanel>
		<TabPanel :key="enumTabs.INVOICEADDRESS" v-if="shouldShowTab(enumTabs.INVOICEADDRESS)" :header="$t('myCadac.accountSettings.tabView.headerInvoice')">
			<p v-if="vm.invoiceDebtorAccountNumber" class="text-muted mb-2">
				{{
					$t("myCadac.accountSettings.lblinvoiceDebtor", {
						name: vm.invoiceDebtorName,
						accountNumber: vm.invoiceDebtorAccountNumber,
					})
				}}
			</p>
			<AddressForm v-model="invoiceAddressFormModel" ref="invoiceAddressForm" :countries="vm.countries" />
			<Button class="p-button-success p-button-raised p-button-lg"
					:disabled="!canSubmit"
					:label="$t('common.save')"
					:loading="isLoading"
					@click="onSubmitClick" />
		</TabPanel>
	</TabView>
</template>

<script lang="ts">
import AddressForm from "@/components/common/forms/addressForm.vue";
import BaseComponent from "@/components/base/baseComponent.vue";
import CompanyForm from "@/components/common/forms/companyForm.vue";
import NewPasswordForm from "@/components/common/forms/newPasswordForm.vue";
import PersonalForm from "@/components/common/forms/personalForm.vue";
import RelatedContactTable from "@/components/accountSettings/relatedContactTable.vue";
import { AddressForm as AddressFormModel } from "@/types/models/form/addressForm";
import { CompanyForm as CompanyFormModel } from "@/types/models/form/companyForm";
import { Component, Prop, Ref } from "vue-facing-decorator";
import { FormComponent } from "@/types/models/form/formComponent";
import { IAccountSettings } from "@/types/viewModels/myCadac/accountSettingsViewModel";
import { Log } from "@/types/helpers/logHelper";
import { PersonalForm as PersonalFormModel } from "@/types/models/form/personalForm";
import { ProfilePassword } from "@/types/models/form/profilePassword";
import { PropType } from "vue";

@Component({
	components: { RelatedContactTable, PersonalForm, AddressForm, CompanyForm, NewPasswordForm },
})
export default class AccountSettings extends BaseComponent {
	@Prop({ type: Object as PropType<IAccountSettings>, required: true, default: {} }) vm!: IAccountSettings;

	@Ref readonly companyForm!: FormComponent<CompanyFormModel>;
	@Ref readonly invoiceAddressForm!: FormComponent<AddressFormModel>;
	@Ref readonly personalForm!: FormComponent<PersonalFormModel>;
	@Ref readonly passwordForm!: FormComponent<ProfilePassword>;

	enumTabs = {
        PERSONAL: 0,
        PASSWORD: 1,
        COMPANY: 2,
        INVOICEADDRESS: 3,
    };

	activeTab = this.enumTabs.PASSWORD;
	visibleTabs: number[] = [];
	isLoading = false;
	reloadRefs = true;

	passwordFormModel!: ProfilePassword;
	companyFormModel!: CompanyFormModel;
	invoiceAddressFormModel!: AddressFormModel;
	personalFormModel!: PersonalFormModel;

	// Trigger reload of variable used in getters so the Refs get loaded properly.
	beforeCreate() {
		setTimeout(() => {
			this.reloadRefs = !this.reloadRefs; // change var, so getters containing it get loaded and refs work... :'(
		}, 10);
	}

	created(): void {
		Log.info("AccountSettings", this.vm);
		const { company, personal, invoiceAddress } = this.vm;
		this.personalFormModel = personal;
		this.companyFormModel = company;
		this.invoiceAddressFormModel = invoiceAddress;

		if (this.showPersonalTab) {
			this.visibleTabs.push(this.enumTabs.PERSONAL);
			this.activeTab = this.enumTabs.PERSONAL;
		}
		this.visibleTabs.push(this.enumTabs.PASSWORD);
		if (this.showCompanyTab) this.visibleTabs.push(this.enumTabs.COMPANY);
		if (this.showInvoiceAddressTab) this.visibleTabs.push(this.enumTabs.INVOICEADDRESS);
	}

	get activeIndex() {
      return this.visibleTabs.indexOf(this.activeTab);
    }

	shouldShowTab(tabKey: number) {
		return this.visibleTabs.includes(tabKey);
	}

	onTabChange(event) {
      const selectedTabIndex = event.index;

	  this.selectActiveTab(this.visibleTabs[selectedTabIndex]);
    }

	selectActiveTab(tabKey: number) {
        this.activeTab = tabKey;
    }

	get isAccountVerified(): boolean {
		return this.layoutStore.isImpersonated
			? this.layoutStore.vm.impersonatedUser.isEmailVerified
			: this.layoutStore.vm.currentUser.isEmailVerified;
	}

	get canSubmit(): boolean {
		return this.isActiveTabDirty && this.isActiveTabValid && !this.isLoading;
	}

	get isActiveTabValid(): boolean {
		this.reloadRefs;  // Lees de waarde om dependency tracking te triggeren
		if (!this.isAccountVerified) return !!this.passwordForm?.isValid;
		switch (this.activeTab) {
			case 0:
				return this.personalForm?.isValid;
			case 1:
				return this.passwordForm?.isValid;
			case 2:
				return this.companyForm?.isValid;
			case 3:
				return this.invoiceAddressForm?.isValid;
		}
		return true;
	}

	get isActiveTabDirty(): boolean {
		this.reloadRefs; // Lees de waarde om dependency tracking te triggeren
		if (!this.isAccountVerified) return this.passwordForm?.isDirty;
		switch (this.activeTab) {
			case 0:
				return this.personalForm?.isDirty;
			case 1:
				return this.passwordForm?.isDirty;
			case 2:
				return this.companyForm?.isDirty;
			case 3:
				return this.invoiceAddressForm?.isDirty;
		}
		return false;
	}

	get showPersonalTab(): boolean {
		return this.isAccountVerified && !!this.vm.personal;
	}

	get showInvoiceAddressTab(): boolean {
		return this.isAccountVerified && !!this.vm.invoiceAddress;
	}

	get showCompanyTab(): boolean {
		return this.isAccountVerified && !!this.vm.company;
	}

	onSubmitClick(): void {
		if (!this.isActiveTabValid || !this.isActiveTabDirty) return;
		if (!this.isAccountVerified) {
			this.savePassword();
		} else {
			switch (this.activeTab) {
				case 0:
					this.savePersonalInfo();
					break;
				case 1:
					this.savePassword();
					break;
				case 2:
					this.saveCompanyInfo();
					break;
				case 3:
					this.saveInvoiceAddress();
					break;
			}
		}
	}

	private savePersonalInfo(): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/userprofile/updatePersonal`, this.personalFormModel)
			.then(() => this.showSuccessToast())
			.catch(() => this.showErrorToast())
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	private savePassword(): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/userprofile/updatePassword`, this.passwordFormModel)
			.then(() => this.showSuccessToast())
			.catch(() => this.showErrorToast())
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	private saveCompanyInfo(): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/userprofile/updateCompany`, this.companyFormModel)
			.then(() => this.showSuccessToast())
			.catch(() => this.showErrorToast())
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	private saveInvoiceAddress(): void {
		this.loadingStore.increaseLoadingCount();
		this.axios
			.post(`/api/userprofile/updateInvoiceAddress`, this.invoiceAddressFormModel)
			.then(() => this.showSuccessToast())
			.catch(() => this.showErrorToast())
			.finally(() => this.loadingStore.decreaseLoadingCount());
	}

	private showSuccessToast(): void {
		this.$toast.add({
			severity: "success",
			summary: this.$t("common.messages.titleSuccess"),
			detail: this.$t("common.messages.saveChangesSuccess"),
			life: 3000,
		});
	}

	private showErrorToast(): void {
		this.$toast.add({
			severity: "error",
			summary: this.$t("common.messages.titleError"),
			detail: this.$t("common.messages.saveChangesError"),
			life: 3000,
		});
	}
}
</script>

<style scoped lang="scss"></style>
