import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-513c3e35"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "personal-overview d-flex flex-column"
}
const _hoisted_2 = { class: "logged-in-user-text mb-0 px-1" }
const _hoisted_3 = {
  key: 0,
  class: "mb-0 px-1 text-info"
}
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = {
  key: 1,
  class: "mb-0 px-1 text-info"
}
const _hoisted_6 = ["href", "title"]
const _hoisted_7 = { class: "wallet-text pr-1" }
const _hoisted_8 = { class: "d-block" }
const _hoisted_9 = { class: "d-block text-fade" }
const _hoisted_10 = {
  key: 0,
  class: "mb-0 d-block text-break"
}
const _hoisted_11 = { class: "wallet-text pr-1" }
const _hoisted_12 = {
  key: 0,
  class: "d-block"
}
const _hoisted_13 = {
  key: 1,
  class: "d-block text-fade"
}
const _hoisted_14 = {
  key: 1,
  class: "mb-0 d-block text-break"
}
const _hoisted_15 = {
  key: 3,
  class: "d-flex flex-column"
}
const _hoisted_16 = {
  key: 4,
  class: "d-flex flex-column"
}
const _hoisted_17 = {
  key: 1,
  class: "d-flex flex-column"
}
const _hoisted_18 = { class: "p-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.layoutStore.isAuthenticated)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          (_ctx.layoutStore.isImpersonated)
            ? (_openBlock(), _createBlock(_component_i18n_t, {
                key: 0,
                keypath: "menu.loggedInAsCustomer"
              }, {
                user: _withCtx(() => [
                  (_ctx.vm.impersonatedUser?.firstName)
                    ? (_openBlock(), _createElementBlock("b", _hoisted_3, [
                        _createElementVNode("a", {
                          class: "d-block",
                          href: _ctx.vm?.impersonatedUser?.crmUrl,
                          target: "_blank",
                          title: _ctx.$t('common.goToCrm')
                        }, _toDisplayString(_ctx.vm.impersonatedUser?.firstName) + " " + _toDisplayString(_ctx.vm.impersonatedUser?.lastName), 9, _hoisted_4)
                      ]))
                    : (_openBlock(), _createElementBlock("b", _hoisted_5, [
                        _createElementVNode("a", {
                          class: "d-block text-break",
                          href: _ctx.vm?.impersonatedUser?.crmUrl,
                          target: "_blank",
                          title: _ctx.$t('common.goToCrm')
                        }, _toDisplayString(_ctx.vm.impersonatedUser?.displayName), 9, _hoisted_6)
                      ])),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.vm.currentUser?.company?.companyName), 1),
                    _createElementVNode("small", _hoisted_9, _toDisplayString(_ctx.vm.currentUser?.company?.cadacOrganizationCode), 1)
                  ])
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_i18n_t, {
                key: 1,
                keypath: "menu.loggedInAs"
              }, {
                user: _withCtx(() => [
                  (_ctx.vm.currentUser?.firstName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("b", null, _toDisplayString(_ctx.vm.currentUser?.firstName) + " " + _toDisplayString(_ctx.vm.currentUser?.lastName), 1),
                          (_ctx.vm.currentUser?.isEmailVerified)
                            ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.vm.currentUser?.company?.companyName), 1))
                            : _createCommentVNode("", true),
                          (_ctx.vm.currentUser?.isEmailVerified)
                            ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.vm.currentUser?.company?.cadacOrganizationCode), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("b", _hoisted_14, _toDisplayString(_ctx.vm.currentUser?.displayName), 1))
                ]),
                _: 1
              }))
        ]),
        _createVNode(_component_Divider),
        (_ctx.vm.myCadacPageUrl && !_ctx.shouldVerifyEmail)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "p-button-primary p-button-raised ml-1 mb-2",
              icon: "pi pi-arrow-right ml-2",
              "icon-pos": "right",
              label: _ctx.$t('menu.button.myCadac'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onGoToMyCadacClick()))
            }, null, 8, ["label"]))
          : (_ctx.vm.myCadacPageUrl)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                class: "p-button-success p-button-raised ml-1 mb-2",
                icon: "pi pi-arrow-right ml-2",
                "icon-pos": "right",
                label: _ctx.$t('menu.button.verifyEmail'),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onGoToMyCadacClick()))
              }, null, 8, ["label"]))
            : _createCommentVNode("", true),
        (_ctx.vm.organizationOptions?.length > 1)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              class: "p-button-secondary p-button-rounded p-button-text ml-0",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeCompanyClick()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  class: "mr-1",
                  icon: ['far', 'arrow-right-arrow-left']
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu.button.changeOrganisation")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.layoutStore.isImpersonated)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_Button, {
                class: "p-button-secondary p-button-rounded p-button-text",
                label: _ctx.$t('menu.button.logout'),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onLogoutClick()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "mr-1",
                    icon: ['far', 'arrow-right-from-bracket']
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu.button.logout")), 1)
                ]),
                _: 1
              }, 8, ["label"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(_component_Button, {
                class: "p-button-text p-button-rounded font-weight-normal ml-0",
                title: _ctx.$t('menu.button.refreshUserData_title'),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.refreshUserInfo()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "mr-1",
                    icon: ['far', 'rotate-right'],
                    size: "lg"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu.button.refreshUserData_title")), 1)
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Button, {
                class: "p-button-text p-button-rounded p-button-secondary ml-0",
                title: _ctx.$t('menu.button.cancelImpersonation_title'),
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancelImpersonation()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "mr-1",
                    icon: ['far', 'arrow-right-from-bracket'],
                    size: "lg"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu.button.cancelImpersonation_title")), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ]))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_17, [
        _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("menu.youAreNotLoggedIn")), 1),
        _createVNode(_component_Button, {
          class: "p-button-success p-button-raised ml-1 mb-1",
          label: _ctx.$t('common.login'),
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onLoginClick()))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          class: "p-button-text ml-1 mb-1",
          label: _ctx.$t('common.register'),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onCreateAccountClick()))
        }, null, 8, ["label"])
      ]))
}