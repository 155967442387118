import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e12944a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "row h-100" }
const _hoisted_3 = ["alt", "title"]
const _hoisted_4 = { class: "col-lg-8" }
const _hoisted_5 = { class: "d-flex mb-2" }
const _hoisted_6 = { class: "align-self-center mr-1" }
const _hoisted_7 = ["alt", "title"]
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "mb-0 text-muted lead" }
const _hoisted_10 = { class: "mb-0 pr-5" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "col-lg-4 mt-2 mt-lg-0 d-flex justify-content-end flex-column" }
const _hoisted_13 = {
  key: 1,
  class: "lead text-muted d-flex flex-wrap text-right justify-content-end"
}
const _hoisted_14 = { class: "" }
const _hoisted_15 = {
  key: 0,
  class: "ml-1 text-faded"
}
const _hoisted_16 = { class: "text-primary ml-1" }
const _hoisted_17 = {
  key: 0,
  class: ""
}
const _hoisted_18 = {
  key: 2,
  class: "lead text-muted text-lg-right"
}
const _hoisted_19 = ["alt", "title"]
const _hoisted_20 = { class: "d-flex flex-wrap flex-column h-100 justify-content-between" }
const _hoisted_21 = { class: "d-flex justify-content-between flex-wrap-reverse" }
const _hoisted_22 = { class: "mr-1" }
const _hoisted_23 = ["alt", "title"]
const _hoisted_24 = { class: "d-flex flex-column flex-1" }
const _hoisted_25 = { class: "mb-0 text-muted lead" }
const _hoisted_26 = { class: "mb-0" }
const _hoisted_27 = {
  key: 1,
  class: "price-text lead text-muted d-flex flex-wrap text-right justify-content-end mb-0"
}
const _hoisted_28 = { class: "" }
const _hoisted_29 = {
  key: 0,
  class: "ml-1 text-faded"
}
const _hoisted_30 = { class: "text-primary ml-1" }
const _hoisted_31 = {
  key: 0,
  class: ""
}
const _hoisted_32 = {
  key: 2,
  class: "lead price-text text-muted text-lg-right pr-2"
}
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = {
  key: 1,
  class: "promotion"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_AvatarGroup = _resolveComponent("AvatarGroup")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Tag = _resolveComponent("Tag")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("a", {
    class: "product-card-item",
    href: _ctx.item?.productPageUrl,
    style: {"text-decoration":"none"}
  }, [
    (!_ctx.isGridLayout)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 0,
          class: "product-list-card",
          style: _normalizeStyle({ backgroundColor: _ctx.item.backgroundColor }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUrl(_ctx.item.productPageUrl)))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.showHeaderImage && _ctx.item.overviewImageVerticalUrl)
                ? _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 0,
                    alt: _ctx.item.iconAlt,
                    class: "overview-image",
                    title: _ctx.item.name
                  }, null, 8, _hoisted_3)), [
                    [_directive_lazy, _ctx.item.overviewImageVerticalUrl]
                  ])
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.item.iconUrl)
                      ? _withDirectives((_openBlock(), _createElementBlock("img", {
                          key: 0,
                          alt: _ctx.item.iconAlt,
                          class: "overview-product-icon",
                          title: _ctx.item.name
                        }, null, 8, _hoisted_7)), [
                          [_directive_lazy, _ctx.item.iconUrl]
                        ])
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.item.brand), 1),
                    _createElementVNode("h4", _hoisted_10, _toDisplayString(_ctx.formatProductName(_ctx.item.name, _ctx.item.brand)), 1)
                  ])
                ]),
                (_ctx.showIntro)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: "mb-0 intro-text text-preline",
                      innerHTML: _ctx.item.intro
                    }, null, 8, _hoisted_11))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_12, [
                (_ctx.isCollectionItem)
                  ? (_openBlock(), _createBlock(_component_AvatarGroup, { key: 0 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsSlice(_ctx.item), (product, index) => {
                          return (_openBlock(), _createBlock(_component_Avatar, {
                            key: index,
                            alt: product.iconAlt,
                            image: product.iconUrl,
                            shape: "square",
                            title: product.name
                          }, null, 8, ["alt", "image", "title"]))
                        }), 128)),
                        (
									_ctx.item.products.length > _ctx.maxProductsToShow &&
									_ctx.item.products.length - _ctx.maxProductsToShow !== 1
								)
                          ? (_openBlock(), _createBlock(_component_Avatar, {
                              key: 0,
                              class: "text-bold mx-1",
                              label: `+${_ctx.item.products.length - _ctx.maxProductsToShow}`,
                              shape: "square"
                            }, null, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.item.price)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
                      _createElementVNode("span", null, [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("common.from")), 1),
                        (_ctx.item.basePrice !== _ctx.item.price)
                          ? (_openBlock(), _createElementBlock("s", _hoisted_15, _toDisplayString(_ctx.formatCurrency(_ctx.item.basePrice)), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("span", null, [
                        _createElementVNode("b", _hoisted_16, _toDisplayString(_ctx.formatCurrency(_ctx.item.price)), 1),
                        (!_ctx.isServiceItem)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t("common.pricePerMonth")), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : ((_ctx.item.availableOnRequest || !_ctx.item.basePrice) && !_ctx.item.label)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.$t("product.availableOnRequest")), 1))
                    : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }, 8, ["style"]))
      : (_openBlock(), _createBlock(_component_Card, {
          key: 1,
          class: _normalizeClass(["product-grid-card", {
				'hover-intro': _ctx.showIntroOnHover,
				'product-collection-item': _ctx.isCollectionItem,
				'cross-sell': _ctx.isCrossSell,
			}]),
          style: _normalizeStyle({ backgroundColor: _ctx.isCollectionItem && _ctx.item.backgroundColor }),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openUrl(_ctx.item.productPageUrl)))
        }, _createSlots({
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  (_ctx.item.iconUrl)
                    ? _withDirectives((_openBlock(), _createElementBlock("img", {
                        key: 0,
                        alt: _ctx.item.iconAlt,
                        class: "overview-product-icon",
                        title: _ctx.item.name
                      }, null, 8, _hoisted_23)), [
                        [_directive_lazy, _ctx.item.iconUrl]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.item.brand), 1),
                  _createElementVNode("h4", _hoisted_26, _toDisplayString(_ctx.formatProductName(_ctx.item.name, _ctx.item.brand)), 1)
                ]),
                (_ctx.isCrossSell)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      class: "mb-auto ml-auto",
                      value: _ctx.$t('enum.itemProductType.' + _ctx.item.productType)
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isCollectionItem)
                ? (_openBlock(), _createBlock(_component_AvatarGroup, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsSlice(_ctx.item), (product, index) => {
                        return (_openBlock(), _createBlock(_component_Avatar, {
                          key: index,
                          alt: product.iconAlt,
                          image: product.iconUrl,
                          shape: "square",
                          size: "large",
                          title: product.name
                        }, null, 8, ["alt", "image", "title"]))
                      }), 128)),
                      (
								_ctx.item.products.length > _ctx.maxProductsToShow &&
								_ctx.item.products.length - _ctx.maxProductsToShow !== 1
							)
                        ? (_openBlock(), _createBlock(_component_Avatar, {
                            key: 0,
                            class: "text-bold mx-1",
                            label: `+${_ctx.item.products.length - _ctx.maxProductsToShow}`,
                            shape: "square",
                            size: "small"
                          }, null, 8, ["label"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.item.price)
                ? (_openBlock(), _createElementBlock("p", _hoisted_27, [
                    _createElementVNode("span", null, [
                      _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("common.from")), 1),
                      (_ctx.item.basePrice !== _ctx.item.price)
                        ? (_openBlock(), _createElementBlock("s", _hoisted_29, _toDisplayString(_ctx.formatCurrency(_ctx.item.basePrice)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("span", null, [
                      _createElementVNode("b", _hoisted_30, _toDisplayString(_ctx.formatCurrency(_ctx.item.price)), 1),
                      (!_ctx.isServiceItem)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.$t("common.pricePerMonth")), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : ((_ctx.item.availableOnRequest || !_ctx.item.basePrice) && !_ctx.item.label)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_32, _toDisplayString(_ctx.$t("product.availableOnRequest")), 1))
                  : _createCommentVNode("", true)
            ])
          ]),
          footer: _withCtx(() => [
            (_ctx.showIntro)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "mb-0 intro-text text-preline pr-2",
                  innerHTML: _ctx.item.intro
                }, null, 8, _hoisted_33))
              : (_ctx.item.label)
                ? (_openBlock(), _createElementBlock("div", _hoisted_34, _toDisplayString(_ctx.item.label), 1))
                : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (!_ctx.isMobile && _ctx.showHeaderImage && _ctx.item.overviewImageUrl)
            ? {
                name: "header",
                fn: _withCtx(() => [
                  _withDirectives(_createElementVNode("img", {
                    alt: _ctx.item.iconAlt,
                    title: _ctx.item.name
                  }, null, 8, _hoisted_19), [
                    [_directive_lazy, _ctx.item.overviewImageUrl]
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["class", "style"]))
  ], 8, _hoisted_1))
}